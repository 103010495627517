import React, { FC, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Descr from "./components/Descr/Descr";
import ListCards from "./components/ListCards/ListCards";
import Footer from "./components/Footer/Footer";
import Modal from "react-modal";
import transition from "./pics/transition.png";

const serverUrl = "https://indyx-server-3tg67jmjeq-uc.a.run.app";

interface OpenClosetResponse {
  status: string;
  user: any;
  items: Array<any>;
}

function useOpenCloset(userName: string) {
  const [data, setData] = useState<OpenClosetResponse | null>({
    user: null,
    items: [],
    status: "LOADING",
  });
  useEffect(() => {
    const getData = async () => {
      if (!userName) {
        setData({ user: null, items: [], status: "ERROR" });
        return;
      }
      const res = await fetch(`${serverUrl}/users/openCloset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userName,
        }),
      });
      const json = await res.json();
      if (json.items) {
        json.items.sort((a: any, b: any) => b.createdAt - a.createdAt);
      }
      setData({ user: json.user, items: json.items, status: json.status });
    };
    try {
      getData();
    } catch (e) {
      setData({ user: null, items: [], status: "ERROR" });
    }
  }, [userName]);
  return { data };
}

function App() {
  const path = window.location.pathname;
  const pathArray = path.split("/");
  const isUser = pathArray[1] === "user";
  const username = isUser ? pathArray[2] || "" : "";
  const { data } = useOpenCloset(username);
  const status = data?.status;
  const user = data?.user;
  const items = data?.items ?? [];

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mode, setMode] = React.useState(1);

  function openModal1() {
    setMode(1);
    setIsOpen(true);
  }

  function openModal2() {
    setMode(2);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const userFirstName = user?.username ?? "";

  return (
    <div className="App">
      {status === "OK" ? (
        <div className="wrapper">
          <Header
            logoUrl={`/icons/logo.png`}
            userName={user?.firstName ?? ""}
          />
          <ListCards items={items} onShowModal={openModal1} />
          <Footer onDownload={openModal2} />
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "0",
                background: "transparent",
                border: "none",
              },
            }}
            contentLabel="Download Modal"
          >
            <div
              style={{
                width: "330px",
                height: "288px",
                borderRadius: "50px 0px",
                border: "0.5px solid #000",
                background: "#F5F1E5",
              }}
            >
              <div onClick={closeModal}></div>
              <div
                style={{
                  marginTop: "59px",
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Spezia",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={{ fontSize: 16, fontFamily: "Roobert" }}>
                  {mode === 1 ? "Not so fast!" : "Get the App"}
                </div>
                <div
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    fontSize: 12,
                    fontFamily: "Spezia",
                  }}
                >
                  {mode === 1
                    ? `You need to download the app and create an Indyx account to
                  track ${userFirstName}'s items.`
                    : `Join Indyx to interact with ${userFirstName}'s closet and build your own.`}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    href="https://apps.apple.com/us/app/indyx-wardrobe-outfit-app/id1599179405"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      width={126}
                      src="https://images.squarespace-cdn.com/content/v1/6321094c82fde37e691eb3f2/520f1895-8890-48ad-a27d-f53eb21b8376/App%2BStore%2BBadge.png?format=750w"
                      alt="ios"
                    />
                  </a>
                  <div style={{ width: 15 }}></div>
                  <a
                    href="https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.indyx.android"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      width={138}
                      src="https://images.squarespace-cdn.com/content/v1/6321094c82fde37e691eb3f2/a5ca2abb-b29c-4f8f-a453-4910d057062a/Google%2BPlay%2BBadge.png?format=750w"
                      alt="android"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
      {status === "ERROR" ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 40,
            backgroundImage: `url(${transition})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "center",
          }}
        >
          404
        </div>
      ) : null}
      {status === "LOADING" ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 40,
            backgroundImage: `url(${transition})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "center",
          }}
        >
          LOADING...
        </div>
      ) : null}
    </div>
  );
}

export default App;
