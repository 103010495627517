/* eslint-disable no-func-assign */
import { FC } from "react";
import styles from "./ListCards.module.css";
import Card from "../Card/Card";
import useWindowSize from "../../hooks/useWindowSize";
import { widthCard } from "../../constants";

interface Props {
  items: Array<any>;
  onShowModal: () => void;
}
const ListCards: FC<Props> = ({ items, onShowModal }) => {
  const windowSize = useWindowSize();
  ////
  let cardsinRow = 2;
  function cardsInRow(screen: number) {
    if (screen < widthCard * 3) {
      cardsinRow = 2;
    } else if (screen < widthCard * 4) {
      cardsinRow = 3;
    } else if (screen < widthCard * 5) {
      cardsinRow = 4;
    } else if (screen < widthCard * 6) {
      cardsinRow = 5;
    } else {
      cardsinRow = 6;
    }
    return cardsinRow;
  }
  cardsInRow(windowSize.width);

  const width = (windowSize.width - 2 * 0.67 * cardsinRow) / cardsinRow;
  return (
    <div className={styles.listCards}>
      {items.length ? (
        items.map((item) => {
          return (
            <Card
              key={item.id}
              onShowModal={onShowModal}
              onClick={() => {
                if (item.itemShoppingURL) {
                  window.open(item.itemShoppingURL);
                } else {
                  onShowModal();
                }
              }}
              itemPhotoURL={item.thumbnailImageURL}
              brandName={item.brand}
              itemShoppingUrl={item.itemShoppingURL ?? ""}
              width={width}
            />
          );
        })
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          LOADING...
        </div>
      )}
    </div>
  );
};
export default ListCards;
