import { FC } from "react";
import styles from "./Card.module.css";
import { aspectRation } from "../../constants";

const iconURL1 = `/icons/block.svg`;
const iconURL2 = `/icons/bell.svg`;
const iconURL3 = ``;

interface PropTypes {
  itemPhotoURL: string;
  itemShoppingUrl: string;
  onShowModal: () => void;
  onClick: () => void;
  brandName: string;
  width: number;
}

const Card: FC<PropTypes> = ({
  itemPhotoURL,
  itemShoppingUrl,
  onShowModal,
  onClick,
  brandName,
  width,
}) => {
  return (
    <div className={styles.card} onClick={onClick} style={{ width: width }}>
      <div className={styles.cardBorder}>
        <div
          className={styles.itemPhoto}
          style={{
            width: width,
            height: width * aspectRation,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={itemPhotoURL}
            alt=""
            style={{
              maxHeight: "95%",
              maxWidth: "95%",
              display: "block",
              margin: "auto",
            }}
          />
        </div>
        <div className={styles.cardDescr}>
          <div
            className={styles.brandName}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {brandName}
          </div>
          <div className={styles.iconsControl}>
            {itemShoppingUrl ? <img src={iconURL1} alt="icon1" /> : null}
            {iconURL2 ? (
              <img
                src={iconURL2}
                alt="icon2"
                onClick={(e) => {
                  e.stopPropagation();
                  onShowModal();
                }}
              />
            ) : null}
            {iconURL3 ? <img src={iconURL3} alt="icon3" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
