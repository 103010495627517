import { FC } from "react";
import styles from "./Header.module.css";
interface PropTypes {
  userName: string;
  logoUrl: string;
}

const Header: FC<PropTypes> = ({ userName, logoUrl: photoURL }) => {
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src={photoURL} width={102} height={31} alt="logo" />
      </div>
      <div className={styles.slogan}>
        Browse {userName}’s real closet.  Join Indyx to interact with it.
      </div>
    </div>
  );
};
export default Header;
