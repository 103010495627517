import { FC } from "react";
import styles from "./Footer.module.css";

interface PropTypes {
  onDownload: () => void;
}

interface ButtonProps {
  text: string;
  onClick: () => void;
}

export const DialogButton: FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <div className={styles.footerBtn} onClick={onClick}>
      <div className={styles.textBtn}>{text}</div>
    </div>
  );
};

const Footer: FC<PropTypes> = ({ onDownload }) => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerBtn} onClick={onDownload}>
        <div className={styles.textBtn}>DOWNLOAD INDYX</div>
        <div className={styles.nextBtn}>
          <img src="/icons/next.png" width={20} height={10} alt="next" />
        </div>
      </div>
    </div>
  );
};
export default Footer;
